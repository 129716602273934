const oneMonthAgo = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000);
const since = Math.floor(oneMonthAgo.getTime() / 1000);
const until = Math.floor(Date.now() / 1000);
const urlTokens = ['USDC', 'DAI', 'TUSD', 'USDT', 'PAXG', 'FRAX'];
const tokens = ['OVG', ...urlTokens];
const period = 'd';
const tokenColors = [
  '#F3B619', // OVG
  '#3DD598', // USDC
  '#D5463D', // DAI
  '#025EF2', // TUSD
  '#16BDF9', // USDT
  '#C1BD98', // PAXG
  '#8C8C8C', // FRAX
];
function transformData(data) {
  return data.map(obj => {
    const createdAt = new Date(obj.created_at);
    const formatter = new Intl.DateTimeFormat('en-US', {
      timeZone: 'UTC',
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
    const dateStr = formatter.format(createdAt);
    obj.rates['PAXG-EUR'] = obj.rates['PAXG-EUR'] / 1600;
    return [dateStr, ...tokens.map(token => obj.rates[`${token}-EUR`])];
  });
}

async function getData() {
  const API_MAIN = process.env.WALLET_URL;
  const url = `${API_MAIN}v4/rs/diffs?since=${since}&until=${until}&tokens=${urlTokens.join(
    ','
  )}&period=${period}`;

  try {
    const response = await fetch(url);
    const data = await response.json();
    return transformData(data);
  } catch (error) {
    console.error('Error fetching data:', error);
    return [];
  }
}

document.addEventListener('DOMContentLoaded', async () => {
  const data = await getData();
  const dataSet = anychart.data.set(data);

  const chart = anychart.line();
  chart.container('grafic_wrapper');

  chart.crosshair().enabled(true).yLabel(true).yStroke(null);
  chart.yScale().maximumGap(0).minimumGap(0);
  chart
    .yAxis()
    .labels()
    .format(function () {
      const originalValue = this.tickValue;
      const adjustedValue =
        originalValue > 1.06
          ? (originalValue * 1600).toFixed(0)
          : originalValue;
      return '€' + adjustedValue;
    });

  tokens.forEach((token, index) => {
    const seriesData = dataSet.mapAs({ x: 0, value: index + 1 });

    const series = chart.line(seriesData);
    series.name(`${token}/EUR`).stroke({
      color: tokenColors[index],
      thickness: index === 0 ? 3 : 1,
    });

    series.tooltip().format(function () {
      const originalValue = this.value;
      const adjustedValue =
        token === 'PAXG'
          ? (originalValue * 1600).toFixed(2)
          : originalValue.toFixed(4);
      return `${token}/EUR : €${adjustedValue}`;
    });
  });

  chart.yGrid(true).yGrid().stroke('#0A0E17 0.9');
  chart.xGrid(true).xGrid().stroke('#0A0E17 0.9');
  chart
    .legend()
    .enabled(true)
    .itemsSpacing(20)
    .padding(30)
    .fontSize(20)
    .fontColor('#becde3');

  chart.xAxis().labels().rotation(-45);
  chart.background().fill('#222A33');
  chart.tooltip().background().fill('#0D1117');

  const legendItem = chart.getSeries(0).legendItem();
  legendItem.enabled(false);

  chart.draw();
});
